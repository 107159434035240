import './App.css';
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faLocation } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-scroll';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

import boutique from './Pictures/boutique.jpeg';
import logo from './Pictures/logoKielwasserWithoutBkg.png'

import FirstPicture from "./Pictures/2AB4D2A9-7B2C-4188-976E-315929302574.jpg";
import SecondPicture from "./Pictures/5E15E808-90F3-4201-A898-CFD87A83C19F.jpg";
import ThirdPicture from "./Pictures/68C49253-F217-4B25-8DBC-4DC59914D031.jpg";
import FourthPicture from "./Pictures/0105CD47-759F-4A11-8F02-A29938BFECB4.jpg";
import FifthPicture from "./Pictures/130E5FAB-BD8C-4A08-909D-E08D5A6591EB.jpg";
import SixthPicture from "./Pictures/624BAABA-BBC6-476E-80BA-41B9F6D677A2.jpg";
import SeventhPicture from "./Pictures/35445F64-8B38-4C1F-8359-7AE9008F9298.jpg";
import EighthPicture from "./Pictures/92865B44-7337-4110-A25C-83D89BC9ED2A.jpg";
import TenthPicture from "./Pictures/A2D7F739-CED8-45B7-A78B-7FB3ACA6867D.jpg";
import EleventhPicture from "./Pictures/FD096538-3113-4327-A1B4-F1E9B8C5B5EC.jpg";

import Seb from './Pictures/a7c0ab03-f5a6-4232-90da-56aefef877f4.jpg'
import Boucherie from './Pictures/boutiqueAll.jpg'


const cards = [
  { text: 'Bouqets de mariage', imageUrl: boutique },
  { text: 'Plantes d exterieur', imageUrl: Seb },
  { text: 'Plantes d intérieur', imageUrl: Boucherie },
];

const gallery = [
  { text: '', imageUrl: FirstPicture },
  { text: '', imageUrl: SecondPicture },
  { text: '', imageUrl: ThirdPicture },
  { text: '', imageUrl: FourthPicture },
  { text: '', imageUrl: FifthPicture },
  { text: '', imageUrl: SixthPicture },
  { text: '', imageUrl: SeventhPicture },
  { text: '', imageUrl: EighthPicture },
  { text: '', imageUrl: TenthPicture },
  { text: '', imageUrl: EleventhPicture },
];

function App() {
  const [showPopup, setShowPopup] = useState(false);

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        setShowPopup(false);
      }
    };
    if (showPopup) {
      document.addEventListener('keydown', handleKeyPress);
    } else {
      document.removeEventListener('keydown', handleKeyPress);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [showPopup]);

  return (
    <div className="App">
      {showPopup && (
        <div className="popup">
          <div className="popupContent">
            <h2 style={{color: 'aliceblue'}} >Mentions Légales</h2>
            <p>Nom de l'entreprise : Boucherie Kielwasser
                <br/>Adresse : 23 rue de la Paix, 68400 Riedisheim, France
                <br/>Téléphone : 03 89 64 42 44
                <br/>SIRET : 85156824600017
                <br/>Représentant légal : Sébastien KIELWASSER
                <br/>Responsable de la publication : Sébastien KIELWASSER
              </p>
              <p>
                <br/>Nom de l'hébergeur : OVH SAS
                <br/>Adresse de l'hébergeur : 2 rue Kellermann - 59100 Roubaix - France
                <br/>Site Web : www.ovh.fr
              </p>
              <p>
                Le contenu du site, incluant les textes, images, graphismes et logo, appartient à la Boucherie Kielwasser et est protégé par la législation française et internationale sur le droit d'auteur et la propriété intellectuelle. Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est strictement interdite sans l'accord exprès par écrit de Boucherie Kielwasser. Cette représentation ou reproduction, par quelque procédé que ce soit, constitue une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle.
              </p>
              <p>
                Les informations personnelles pouvant être recueillies sur le site sont principalement utilisées par l'éditeur pour la gestion des relations avec vous, et le cas échéant, pour le traitement de vos commandes. Conformément aux dispositions de la loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, vous disposez d'un droit d'accès, de rectification, d'opposition et de suppression des données personnelles qui vous concernent. Pour l'exercer, adressez votre demande à Boucherie Kielwasser par email ou par courrier postal signé, accompagné d'une copie du document d'identité avec signature du titulaire de la pièce.
              </p>
              <p>
                Le site peut-être amené à vous demander l'acceptation des cookies pour des besoins de statistiques et d'affichage. Un cookie est une information déposée sur votre disque dur par le serveur du site que vous visitez. Il contient plusieurs données qui sont stockées sur votre ordinateur dans un simple fichier texte auquel un serveur accède pour lire et enregistrer des informations. Certaines parties de ce site ne peuvent être fonctionnelles sans l’acceptation de cookies.
              </p>
              <p>
                Les présentes mentions légales sont soumises à la loi française. En cas de litige, les tribunaux français seront seuls compétents.
              </p>
            <button className="closeButton" onClick={handleClosePopup}>
              X
            </button>
          </div>
        </div>
      )}
      <div className='topBar'>
        <div className='buttonTopBar'>
          <Link activeClass="active" to="home" spy={true} smooth={true} offset={-70} duration={500} className="buttonOurSolutionsFromAbout">
              Accueil
          </Link>
        </div>
        <div className='buttonTopBar'>
          <Link activeClass="active" to="about" spy={true} smooth={true} offset={-70} duration={500} className="buttonOurSolutionsFromAbout">
              A propos
          </Link>
        </div>
        <div className='buttonTopBar'>
          <Link activeClass="active" to="gallery" spy={true} smooth={true} offset={-70} duration={500} className="buttonOurSolutionsFromAbout">
              Galerie
          </Link>
        </div>
      </div>
      <div className='home' id='home'>
        <div className='mainInformationsHomeContainer'>
          <img src={logo} className="Home-logo" alt="logo" />
          <span style={{fontStyle:'italic', marginTop:'10px'}}>lundi: fermé</span>
          <span style={{fontStyle:'italic', marginTop:'10px'}}>mardi: 8h - 12h30, 15h - 18h30</span>
          <span style={{fontStyle:'italic', marginTop:'10px'}}>mercredi: 8h - 12h30, 15h - 18h30</span>
          <span style={{fontStyle:'italic', marginTop:'10px'}}>jeudi: 8h - 12h30, 15h - 18h30</span>
          <span style={{fontStyle:'italic', marginTop:'10px'}}>vendredi: 8h - 18h30</span>
          <span style={{fontStyle:'italic', marginTop:'10px'}}>samedi: 7h30 - 15h</span>
          <span style={{fontStyle:'italic', marginTop:'10px'}}>dimanche: fermé</span>
          <div className='buttonHomeCall'>
            <FontAwesomeIcon icon={faLocation} />
            <a style={{marginLeft:'10px', textDecoration:'none',color:'aliceblue'}} href='https://www.google.com/maps/place/Boucherie+Kielwasser/@47.7469223,7.363426,17z/data=!3m2!4b1!5s0x47919bea56502839:0x9545d1324d1e7a45!4m6!3m5!1s0x47919bea5643ffff:0x2358ed48b95189ef!8m2!3d47.7469224!4d7.3682969!16s%2Fg%2F11j4svjg0z?entry=ttu' target="_blank" rel="noopener noreferrer">23 Rue de la Paix, 68400 Riedisheim</a>
          </div>
          <div className='buttonHomeCall'>
            <FontAwesomeIcon icon={faPhone} />
            <a style={{marginLeft:'10px', textDecoration:'none',color:'aliceblue'}} href='tel:+33389644244'>03 89 64 42 44</a>
          </div>
          <div className='iconsHome'>
            <br/>
            <span style={{marginTop:'150px', fontSize:'calc(0.8rem + 1vmin)'}}>Suivez-nous sur Facebook !</span>
            <br/>
            <a href='https://www.facebook.com/profile.php?id=100057542435577&locale=fr_FR' target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon style={{marginRight:'15px', marginTop:'15px', color:'aliceblue'}} icon={faFacebook} />
            </a>
          </div>
        </div>
      </div>
      <div className='about' id='about'>
        <div className='mainTitlePage'>
          Notre boucherie
        </div>
        <div className='aboutTextContainer'>
            Sébastien Kielwasser est fier de vous accueillir dans sa boucherie.<br/><br/>
            Issu de la famille Tempé son but est de perpétrer la qualité du service et des produits à l'image de ses
            grands-parents et oncles.<br/>
            Un parcours de 07 ans dans la prestigieuse maison SCHMID (traiteur spécialisé en produits d'Alsace)
            à Paris, un retour en Alsace pour intégrer la maison Tempé suivi d'une orientation dans la grande
            distribution tant que chef boucher et pour finir chef de département frais traditionnel lui a permis
            d'avoir une grande expérience du métier.<br/><br/>
            Dans sa boucherie, Sébastien vous propose une sélection rigoureuse des meilleurs artisans et un
            choix de viandes de qualité afin de vous satisfaire.<br/><br/>

            Ses engagements sont la qualité et le service et pour se faire, une équipe de professionnels l'entoure
            au quotidien.<br/><br/>

            Toute l'équipe est heureuse de vous accueillir
        </div>
        <div className="imgAboutContainer">
          <div className="imgAboutWrapper">
            {cards.map((card, index) => (
              <div
              className='imgAbout'
              key={index}
              style={{ backgroundImage: `url(${card.imageUrl})` }}
              >
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='gallery' id='gallery'>
        <div className='mainTitlePage'>
          Galerie
        </div>
          <div className="imgGalleryWrapper">
            {gallery.map((picture, index) => (
              <div
              className='imgGallery'
              key={index}
              style={{ backgroundImage: `url(${picture.imageUrl})` }}
              >
              </div>
            ))}
          </div>
        <div className='aboutTextContainer' style={{marginBottom:'6vh'}}>
          Merci de votre visite !
        </div>
      </div>
      <div className='footer'>
        <a href='https://oscar-dev.fr/' style={{textDecoration:'none', color:'aliceblue', marginBottom:'10px'}} target="_blank" rel="noreferrer">© 2023 oscar-dev</a>
        <span onClick={handleOpenPopup} style={{cursor:'pointer'}} >mentions légales</span>
      </div>
    </div>
  );
}

export default App;
